import React from "react"
import Pagination, {
  PaginationRenderItemParams,
} from "@material-ui/core/Pagination"
import _chunk from "lodash/chunk"
import _head from "lodash/head"
import _map from "lodash/map"
import _round from "lodash/round"

import { animateScroll as ANIMATE_SCROLL } from "react-scroll"

import PaginationItemRender from "./PaginationItem"
import "./styles.less"
import { useMedia } from "react-use-media"

const DEFAULT_PAGE_SIZE = 4
const { scrollToTop } = ANIMATE_SCROLL
interface IHandleChangePagination {
  currentData: any[]
}
interface Props {
  data: any[]
  handleChangePagination: ({ currentData }: IHandleChangePagination) => void
  pageSize?: number
  size: number
}

const CustomPagination = ({
  data,
  handleChangePagination,
  pageSize = DEFAULT_PAGE_SIZE,
  size,
}: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = React.useState(1)

  const isMobile = useMedia({ maxWidth: 767 })

  const count = _round(size / pageSize)
  const boundaryCount = isMobile || count >= 11 ? undefined : 10
  const onChange = (_: unknown, page: number): void => {
    scrollToTop({ smooth: true })
    setCurrentPage((prevState) => {
      const newState = page || prevState
      handleChangePagination({
        currentData: data[newState - 1],
      })
      return newState
    })
  }

  return (
    <Pagination
      className="custom_pagination_wrapper"
      count={count}
      boundaryCount={boundaryCount}
      page={currentPage}
      onChange={onChange}
      renderItem={(params: PaginationRenderItemParams) => (
        <PaginationItemRender {...params} />
      )}
    />
  )
}

export default CustomPagination
