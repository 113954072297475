import * as React from "react"

function SvgArrowScroll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={46} height={46} {...props}>
      <g
        data-name="Elipse 1"
        transform="rotate(90 22 23)"
        fill="transparent"
        stroke="#767677"
        strokeLinejoin="round"
      >
        <circle cx={22} cy={22} r={22} stroke="none" />
        <circle cx={22} cy={22} r={22.5} fill="none" />
      </g>
      <path
        data-name="Ret\xE2ngulo Arredondado 1 copiar 2"
        d="M18.189 21.846l5.574-5.575a1.802 1.802 0 012.548 2.549l-4.3 4.3 4.3 4.3a1.802 1.802 0 01-2.548 2.548l-5.574-5.574a1.8 1.8 0 010-2.548z"
        fill="#02ac9e"
      />
    </svg>
  )
}

export default SvgArrowScroll
