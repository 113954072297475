import React from "react"

import * as Icons from "./components"

interface Props {
  name: string
  className?: string
}
export const Icon = ({ name, ...props }: Props) => {
  const IconComponent = Icons[name]
  if (!IconComponent) {
    return null
  }
  return <IconComponent {...props} />
}
