import React from "react"
import _map from "lodash/map"
import _size from "lodash/size"
import _chunk from "lodash/chunk"
import _head from "lodash/head"

import SearchFormBlog from "../SearchFormBlog"
import SearchResultCard, { ISearchResultCard } from "./SearchResultCard"
import CustomPagination from "../CustomPagination"
import useBlogSearch from "../../hooks/useBlogSearch"

const SearchResult = (): JSX.Element => {
  const context = useBlogSearch()

  const data = _chunk<ISearchResultCard>(context.searchResult, 4)

  const [currentResult, setCurrentResult] = React.useState<ISearchResultCard[]>(
    data[0]
  )
  const [isLoading, setIsLoading] = React.useState(true)

  const containerWrapper = "main-container m-auto pt-16"
  const titlePageClassName =
    "text-maastrichtBlue text-2.375xl font-bold text-center pb-8 m-0 lg:text-5.5xl"
  const featuredTextClassName = "text-persianGreen"

  React.useEffect(() => {
    setIsLoading(true)
    setCurrentResult(_chunk<ISearchResultCard>(context.searchResult, 4)[0])
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [context.searchResult, context.searchParam])

  const handleChangePagination = ({
    currentData,
  }: {
    currentData: any[]
  }): void => setCurrentResult(currentData)

  return (
    <section className={containerWrapper}>
      <h2 className={titlePageClassName}>
        <span className={featuredTextClassName}>Resultados</span> de pesquisa.
      </h2>
      <section className="w-full md:w-[354px] md:m-auto lg:w-[632px] xl:w-[648px]">
        <SearchFormBlog />
      </section>
      <section className="pt-16">
        {_map(currentResult, ({ id, ...data }: ISearchResultCard) => (
          <SearchResultCard key={id} isLoading={isLoading} {...data} />
        ))}
        {!currentResult &&
          <h1 className="text-center text-2xl font-bold text-maastrichtBlue mb-16">Nenhum resultado encontrado!</h1>
        }
      </section>
      {_size(context.searchResult) > 4 && 
      <section className="flex justify-center mb-16">
        <CustomPagination
          data={data}
          size={_size(context.searchResult)}
          pageSize={4}
          handleChangePagination={handleChangePagination}
        />
      </section>
      }
    </section>
  )
}

export default SearchResult
