import React from "react"
import Footer from "../../components/Footer"
import Menu from "../../components/Menu"
import NewsletterBlog from "../../components/NewsletterBlog"
import SearchResult from "../../components/SearchResult"
import { BlogSearchProvider } from "../../hooks/useBlogSearch"



const SearchResultPage = (): JSX.Element => {
  return (
    <BlogSearchProvider>
      <main>
        <section className="main-container m-auto">
          <Menu />
        </section>
        <section className="bg-white">
          <SearchResult />
          <section className="flex newsletter">
            <NewsletterBlog />
          </section>
        </section>
        <Footer blog={true} />
      </main>
    </BlogSearchProvider>
  )
}

export default SearchResultPage
