import React from "react"
import cx from "classnames"
import { PaginationRenderItemParams } from "@material-ui/core/Pagination"
import { Icon } from "../Icons"

const PaginationItemRender = ({
  type,
  page,
  selected,
  ...props
}: PaginationRenderItemParams): JSX.Element => {
  const COMPONENTS_BY_TYPE = {
    page: <>{page}</>,
    previous: <Icon name="ArrowScroll" />,
    next: <Icon name="ArrowScroll" className="-rotate-180" />,
    "end-ellipsis": <>...</>,
    "start-ellipsis": <>...</>,
    first: <>{page}</>,
    last: <>{page}</>,
  }

  const itemWrapperClassName = cx({
    active_item: selected,
    custom_item_pagination: type !== "previous" && type !== "next",
    custom_elipsis: !!type.includes("ellipsis"),
    arrow_next: type === "next",
    arrow_prev: type === "previous",
  })
  return (
    <dt className={itemWrapperClassName}>
      <button {...props}>{COMPONENTS_BY_TYPE[type]}</button>
    </dt>
  )
}

export default PaginationItemRender
