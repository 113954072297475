import React from "react"
import { Link } from "gatsby"
import { Skeleton } from "antd"

import _get from "lodash/get"
import _truncate from "lodash/truncate"
import Badges from "../Badges"
import { IArticleFormatedImage, ITag } from "../../interfaces/article"
import { formatDateToArticleCard } from "../../utils/date"
import "./styles.less"

export interface ISearchResultCard {
  id?: number
  image: IArticleFormatedImage
  tags: ITag[]
  title: string
  description: string
  author: string
  date: string
  slug: string
  isLoading?: boolean
}

const SearchResultCard = ({
  image,
  tags,
  title,
  description,
  author,
  date,
  slug,
  isLoading,
}: ISearchResultCard): JSX.Element => {
  const authorText = `Por ${author}`
  const cardWrapper =
    "mb-16 w-[335px] flex flex-col md:w-[650px] md:m-auto md:mb-16 lg:w-[622px] xl:w-[843px] articleWrapper"
  const linkButtonClassName =
    "w-[fit-content] border-2 border-persianGreen text-persianGreen font-bold px-8 py-3 rounded-[48px] text-base hover:text-persianGreen"
  const titleClassName =
    "text-maastrichtBlue text-2.375xl font-bold pb-4 m-0 leading-[46px] lg:text-5.5xl lg:leading-[64px]"
  const imageClassName =
    "w-full h-[188px] object-cover rounded-[20px] md:h-[367px]"

  const badgeIcon = _get(tags, ["0", "icon"])
  const badgeText = _get(tags, ["0", "text"])
  const badgeIndex = 0
  const articleLink = `/blog/artigo/${slug}/`

  return (
    <Skeleton loading={isLoading} active={isLoading} paragraph={isLoading}>
      <article className={cardWrapper}>
        <figure className="m-0 pb-6 xl:pb-[2.813rem]">
          <img
            src={image.uri}
            srcSet={image.srcSet}
            className={imageClassName}
            alt={image.altText}
          />
        </figure>
        <div className="w-[fit-content] pb-6">
          <Badges
            textWhite
            bgColor="bg-persianGreen border-persianGreen"
            index={badgeIndex}
            icon={badgeIcon}
            text={badgeText}
          />
        </div>
        <h3 className={titleClassName}>{title}</h3>
        <p
          className="m-0 text-base text-jet pb-6"
          dangerouslySetInnerHTML={{
            __html: _truncate(description, { length: 100 }),
          }}
        ></p>
        <p className="text-maastrichtBlue font-bold text-base m-0 pb-4">
          {authorText}
        </p>
        <p className="text-sm text-maastrichtBlue m-0 pb-6">
          {formatDateToArticleCard(date)}
        </p>
        <Link to={articleLink} target="_blank" className={linkButtonClassName}>
          Ler mais
        </Link>
      </article>
    </Skeleton>
  )
}

export default SearchResultCard
